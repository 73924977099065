import { useEffect, useState } from "react";
import { off, on } from "../../../constants/events";
import { useAppContext } from "../../../context/AuthContext";
import { Lecture } from "../../../controller";
import { EVENT_UPDATE_USER_INFO } from "../../../utils/Utils";

export default function UserInfoService() {
  const { selectedCourse, selectedLecture, showMessage } = useAppContext();
  const [userInfo, setUserInfo] = useState<{ pseudonym: string; anonId: string; description: string } | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => setOpen(false);
  const handleToggle = () => setOpen(!open);

  useEffect(() => {
    getLecturePseudonym(selectedCourse?.id, selectedLecture?.lectureNum);

    const handleTrigger = (customEvent: any) => getLecturePseudonym(customEvent.detail?.courseId, customEvent.detail?.lectureNum);
    on(EVENT_UPDATE_USER_INFO, handleTrigger);

    return () => {
      off(EVENT_UPDATE_USER_INFO, handleTrigger);
    };
  }, [selectedLecture, selectedCourse, showMessage]);

  const getLecturePseudonym = (courseId: number, lectureNum: number) => {
    if (!courseId || !lectureNum) {
      setUserInfo(undefined);
      return;
    }
    Lecture.lecturePseudonym(courseId, lectureNum)
      .then(({ data }) => setUserInfo(data.data))
      .catch((err: any) => setUserInfo(undefined));
  };

  return { userInfo, open, handleToggle, handleClose };
}
